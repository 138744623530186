<!-- <template>
    <div>
       <button @click="getSelectedRows()">Get Selected Rows</button>
        <ag-grid-vue
            style="width: 100%; height: 90vh"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :autoGroupColumnDef="autoGroupColumnDef"
            @grid-ready="onGridReady"
        >
        </ag-grid-vue>
    </div>
  </template>
  
  <script>
  import { AgGridVue } from "ag-grid-vue";
  
  export default {
   name: "App",
   data() {
     return {
       columnDefs: null,
       rowData: null,
      gridApi: null,
      autoGroupColumnDef: {
                   headerName: 'Model',
                   field: 'model',
                   cellRenderer: 'agGroupCellRenderer',
                   cellRendererParams: {
                       checkbox: true
                   }
               }
     };
   },
   components: {
     AgGridVue,
   },
   beforeMount() {
     this.columnDefs = [
         { field: 'make', filter: true, checkboxSelection: true, rowGroup: true },
         { field: 'model', filter: true },
         { field: 'price', filter: true }
       ];
  
       fetch('https://www.ag-grid.com/example-assets/row-data.json')
               .then(result => result.json())
               .then(rowData => this.rowData = rowData);
   },
   methods: {
      onGridReady(params) {
          this.gridApi = params.api;
      },
      getSelectedRows() {
          const selectedNodes = this.gridApi.getSelectedNodes();
          const selectedData = selectedNodes.map( node => node.data );
          const selectedDataStringPresentation = selectedData.map( data => `${data.make} ${data.model}`).join(', ');
          alert(`Selected nodes: ${selectedDataStringPresentation}`);
      }
   },
  };
  </script>
  <style lang="scss">
   @import "~ag-grid-community/styles/ag-grid.css";
  </style> -->
 <!-- Landing.vue -->
<template>
  <ag-grid-vue
    style="width: 100%; height: 100vh"
    :columnDefs="columnDefs.columns"
    :rowData="rowData.rows"
    :defaultColDef="defaultColDef.def"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import ActionRenderer from './ContainerComponents/Actions.vue';
import { reactive, h } from "vue";
export default {
  components: {
    AgGridVue, // Ensure this is the correct import from the right package
    ActionRenderer
  },
 data: ()=>({
  rowData: {rows: []}
 }),
  setup(props) {
    const columnDefs = reactive({
      columns: [
        {
          field: "athlete"
        },
        {
          field: "age"
        },
        { field: "country", cellRenderer: "ActionRenderer" },
        {
          field: "year",
          cellRendererSelector: (p) => {
            if (p.value === 2000) {
              return {
                component: "ActionRenderer",
                params: {},
              };
            }
            if (p.value === 2008) {
              return {
                component: "ActionRenderer",
                params: {},
              };
            }
          },
        },
        { field: "date" },
        { field: "sport" },
        { field: "gold" },
        { field: "silver" },
        { field: "bronze" },
        { field: "total" },
      ],
    });
    const defaultColDef = reactive({
      def: {
        sortable: true,
        filter: true,
      },
    });
    const rowData = reactive({
      rows: [],
    });

    return { columnDefs, rowData, defaultColDef };
  },
  mounted() {
    fetch(
      "https://www.ag-grid.com/example-assets/olympic-winners.json"
    )
      .then((resp) => resp.json())
      .then((data) => (this.rowData.rows = data));
  },
  methods: {
    handleEdit(data) {
      console.log('Edit action on', data);
      // Implement your edit logic here
    },
    handleDelete(data) {
      console.log('Delete action on', data);
      // Implement your delete logic here
    },
  },
};
</script>

<style>
/* @import "~@ag-grid-community/styles/ag-grid.css"; */
</style>
